.text-descripcion-container {
    position: relative;
}

.text-descripcion-empresa {
    resize: none;
}

.contador-descripcion {
    width: max-content;
    font-size: 12px;
    position: absolute;
    right: 5px;
    bottom: 1px;
}

.sobrepaso-texto {
    color: red;
}
