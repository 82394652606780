* {
    font-family: Helvetica, sans-serif;
}
.caracteristicas {
    display: flex;
    justify-content: center;
}

.caracteristicas .container .tarjeta {
    border-radius: 5px;
    box-shadow: 2px 2px 10px gray;
    padding: 20px;
    padding-bottom: 40px;
    text-align: center;
    position: relative;
    top: -40px;
    background-color: white;
    min-height: 200px;
}
.caracteristicas .container .tarjeta h4 {
    font-size: 14px;
    font-weight: bold;
}
.caracteristicas .container .tarjeta p {
    font-size: 12px;
}
.caracteristicas .container .tarjeta .icono {
    color: #753183;
    margin: 5px;
}

.tarjeta4 {
    background-color: #753183 !important;
    color: white !important;
}
.tarjeta4 .icono {
    color: white !important;
}
.logros {
    /* height: 400px; */
    background-image: url('../../assets/Fondo_info.png');
    background-size: cover;
    background-position: center;
    color: white;
    display: grid;
    justify-content: center;
    align-content: center;
    padding: 100px;
    text-align: center;
    margin-top: 50px;
}

.logros .container p:nth-of-type(1) {
    font-size: 36px;
}
.logros .container p:nth-of-type(2) {
    font-size: 20px;
}

@media screen and (max-width: 700px) {
    .mensajelogro {
        max-width: 420px;
    }
}

/* .logros .container .numeros {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
} */

.information-container {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
}

.information-container .information {
    width: 250px;
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    box-sizing: border-box;
}

.information-container .information .icono {
    color: #753183;
    margin-bottom: 1rem;
}

.information-container .information > div {
    min-height: 55px;
}

.information-container .information p {
    text-align: center;
    line-height: 0.2;
    font-size: 19px;
}

@media (max-width: 767px) {
    .information-container .information p {
        text-align: center;
        line-height: 0.2;
        font-size: large;
    }
}
