.dropdown-menu {
    min-width: max-content;
}

.dropdown-menu .dropdown-item {
    font-size: 12px;
}

.dropdown-menu .dropdown-item:hover {
    font-size: 12px;
}

@media (max-width: 991px) {
    .dropdown-menu {
        background: #753183;
        border: none;
    }

    .dropdown-divider {
        display: none;
    }

    .dropdown-menu a {
        color: rgba(255, 255, 255, 0.5);
    }

    .dropdown-menu a:hover {
        color: rgba(255, 255, 255, 0.7);
    }

    .dropdown-menu .dropdown-item:hover {
        background: #753183;
    }
}
