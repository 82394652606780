.complete-page {
    background: url(../../assets/machupichu.png);
    background-size: cover;
}

.complete-register .card {
    color: #757575;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    text-align: justify;
}

.complete-register .register-title {
    color: #753183;
}

@media (min-width: 768px) {
    .complete-register .card-body {
        padding: 2rem 3rem;
    }
}
