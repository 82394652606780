.sharedheader{
    height:660px;
    display:grid;
    align-content: center;
    justify-content: center;
    background-size:cover;
    background-position: center;
}
.sharedheader h1{
    font-weight: bold;
    font-size:60px;
    color:white;

}