.tour {
    display: grid;
    /* grid-template:repeat(6,200px) /  */
    grid-template-columns: 0.2fr 0.8fr;
    gap: 30px;
}
label {
    font-weight: bold;
}
.inputlista {
    /*display: grid;
    gap: 5px;
    grid-template-columns: 0.8fr 0.2fr;
    max-width: 100vw;*/
    display: flex;
    flex-direction: column;
}
.lista {
    grid-column: 1 / 3;
}
.incluye ul {
    grid-column: 1 / 3;
}
.addTour {
    grid-column: 1 / 3;
}
.inputmitad {
    display: grid;
    grid-template-columns: 0.5fr 0.5fr;
    gap: 10px;
}
/* correcci{on de maps */
.partida > div {
    height: 300px;
}
.partida > div > div > div {
    height: 300px !important;
    width: 900px !important;
}

.header-list-container,
.list-container {
    display: flex;
}

.header-list-container {
    margin-bottom: 5px;
}

.header-list-container input {
    margin-right: 10px;
}

.partida > div > div > div {
    height: 300px !important;
    width: 900px !important;
}
.box {
    display: flex;
}
.form-check {
    flex: 1 1 auto;
}

.check-dia input {
    width: auto;
    height: auto;
    visibility: visible;
}

.check-dia label {
    font-size: small;
    font-weight: 500;
}

.tipo-turnos .form-check {
    display: flex;
}

.tipo-turnos .form-check label {
    font-size: small !important;
    font-weight: 500 !important;
}

.restricciones {
    display: flex;
    align-items: center;
}
