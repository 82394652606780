.lugares .container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 40px;
    padding: 0 100px;
}
.lugares .container .tarjeta {
    border-radius: 5px;
    box-shadow: 2px 2px 10px gray;
    background-color: white;
}
.lugares .container .tarjeta h4 {
    font-size: 14px;
    font-weight: bold;
}
.lugares .container .tarjeta p {
    font-size: 12px;
}

.lugares .container .titulo {
    grid-column: 1 / 5;
}

.lugares .container .tarjeta .img {
    height: 270px;
    background-image: url("../../../assets/machupichu.png");
    background-size: cover;
    background-position: center;
}
.lugares .container .tarjeta .cuerpo {
    padding: 20px;
}

.img-ratio {
    aspect-ratio: 4/3;
}

.img-ratio-principal {
    aspect-ratio: 16/9;
}

/* .lugares-nombre {
    min-height: 4.5rem;
} */

.lugares-nombre-link {
    color: black;
    text-decoration: none;
}

.lugares-nombre-link:hover {
    color: rgba(0, 0, 0, 0.8);
    text-decoration: none;
}

.lugares-precio {
    display: flex;
    min-width: 4.5rem;
    justify-content: flex-end;
}

/* .lugares-desc {
    min-height: 5rem;
}

.lugares-container-btn {
    margin-top: 0.5rem;
    min-height: 3rem;
    align-items: flex-end !important;
} */

/*.read-more-read-less {
    text-align: justify;
}*/

.carousel-lugares .card-lugares {
    margin: 0 1rem !important;
}

.carousel-lugares .card-lugares .card {
    min-height: 29.5rem;
}

.carousel-lugares .card-lugares .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.carousel-lugares .card-lugares .card-body .card-info {
    justify-self: flex-end;
}

.card-img-top {
    object-fit: cover;
}

/* ========= */

.card-tour {
    width: 250px;
    height: 288px;
    position: relative;
    overflow: hidden;
    border-radius: 7px;
}

.card-tour:hover {
    opacity: 0.9;
}

.card-tour a {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
}

.image-tour {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    max-width: 100%;
    min-height: 100%;
    border-radius: 7px;
    transition: all 1s;
}

.card-tour:hover .image-tour {
    transform: scale(1.1);
}

.detalles-tour {
    display: flex;
    position: absolute;
    padding: 1rem;
    flex-direction: column;
    width: 100%;
    max-width: 250px;
    box-sizing: border-box;
    justify-content: flex-start;
    gap: 7px;
}

.card-tour .tipo-tour {
    border: 1px solid #e37b25;
    max-width: max-content;
    background: #e37b25;
    padding: 0.2rem 0.6rem;
    border-radius: 10px;
    font-size: 0.9em;
}

.card-tour .titulo-tour {
    font-size: large;
    /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
    line-height: 1.1;
}

.card-tour .region-tour {
    font-weight: normal;
    font-size: small;
    /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
}

.card-tour .precio-tour {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid #e37b25;
    background: #e37b25;
    width: calc(40%);
    font-size: 1em;
    text-align: center;
    padding: 0.2rem;
    border-top-left-radius: 20px;
}

.sombra-tarjeta {
    width: 250px;
    height: 288px;
    background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.8),
        rgba(0, 0, 0, 0)
    );
    position: absolute;
    border-radius: 7px;
    top: 0;
    left: 0;
}

/* .react-multiple-carousel__arrow--left,
.react-multiple-carousel__arrow--right {
    display: none !important;
} */

.react-multi-carousel-list {
    height: 320px;
}

.react-multi-carousel-dot-list {
    bottom: 0 !important;
}

.react-multi-carousel-track {
    align-self: flex-start;
}
