.card-empresa-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.card-empresa {
    width: 350px;
    height: 280px;
    display: flex;
    box-sizing: border-box;
    border-radius: 7px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
    font-family: 'ArialMTBold';
}

.card-information {
    width: 280px;
    box-sizing: border-box;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    display: flex;
    flex-direction: column;
}

.card-tours {
    width: 70px;
    box-sizing: border-box;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #842d7d;
}

.card-foto-empresa {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-top-left-radius: 7px;
}

.card-foto-empresa img {
    width: 85px;
    height: 85px;
    border-radius: 50%;
}

.card-info-empresa {
    text-align: center;
    height: 130px;
    border-bottom-left-radius: 7px;
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.card-info-nombre {
    text-align: center;
    font-weight: bold;
    margin: 0;
    color: black;
}

.card-info-nombre:hover {
    color: #842d7d;
    text-decoration: none;
}

.card-info-box {
    margin-top: 0.5rem;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.card-info-data {
    box-sizing: border-box;
    font-size: 12px;
}

.card-info-data-name {
    font-weight: bold;
    margin-right: 0.5rem;
}

.card-tours-stat {
    padding: 2px 0;
    color: white;
}

.card-tours-stat p {
    margin: 0;
    text-align: center;
}

.card-tours-stat-name {
    font-weight: bold;
    font-size: 8px;
}

.card-tours-stat-value {
    font-size: 13px;
}

.card-tours-stat.first-c {
    border-top-right-radius: 7px;
}

.card-tours-stat.last-c {
    border-bottom-right-radius: 7px;
}
