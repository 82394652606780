.header {
    /*background-image: url("../../../assets/machupichu.png") !important;*/
    background-image: url('../../../assets/Itoour_Te_Guia.jpg');
    background-position: center;
    background-size: cover;
    /* padding: 100px 0; */
    /* padding-bottom: 180px; */
    margin-bottom: 3rem;
    color: white;
    position: relative;
    width: 100vw 100%;
    height: calc(100vw / 1.78);
    max-height: 100vh;
    display: grid;
    grid-template-rows: repeat(11, calc(9%));
    grid-template-columns: repeat(11, calc(9%));
    grid-template-areas:
        '. . . . . . . . . . .'
        '. . . . . . . . . . .'
        '. . . . . . . . . . .'
        '. . . . . . . . . . .'
        '. . . . . . . . . . .'
        '. . . . . . . . . . .'
        '. . . . . . . . . . .'
        'search-box search-box search-box search-box search-box search-box search-box search-box search-box search-box search-box'
        '. . . . . . . . . . .'
        '. . . . . . . . . . .';
}

.header .container {
    /* position: absolute;
    bottom: 250px;
    left: 150px; */
    width: 100%;
    padding: 0;
    grid-area: search-box;
}

.texto-header-container {
    max-width: 600px;
}

.header .container p {
    margin: 0;
    margin-bottom: 5px;
    font-size: 32px;
    padding: 0;
    line-height: 1;
}

.header .container h2 {
    font-family: 'Nunito', sans-serif;
    color: #753183;
    font-size: 70px;
}
.header .container h2 > span {
    color: #d6872b;
}

.header .descubre span:nth-of-type(2),
span:nth-of-type(3) {
    display: block;
    text-shadow: 2px 2px 3px black;
    line-height: 45px;
}
.descubre {
    font-size: 42px;
    font-weight: bold;
}
.header span:nth-of-type(2) {
    font-size: 42px;
}
.header span:nth-of-type(3) {
    font-size: 18px;
    font-weight: bold;
    line-height: 50px;
}

.itoour-search-bar {
    display: flex;
    max-width: 500px;
    background-color: white;
    border-radius: 20px;
    padding: 0.3rem 0.3rem 0.3rem 1.2rem;
    margin-top: 1rem;
    position: relative;
}

.itoour-search-bar form {
    width: 100%;
}

.itoour-search-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    max-width: calc(500px - 0.6rem);
}

.itoour-input-search {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    width: calc(100% - 110px);
}

.itoour-input-search:focus {
    border-bottom: 1px solid #753183;
    outline: none;
}

.itoour-btn-search {
    background: #753183;
    color: white;
    font-weight: bold;
    text-align: center;
    width: 110px;
    padding: 0.3rem 0.5rem;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.itoour-btn-search:hover {
    background-color: #863896;
    text-decoration: none;
    color: white;
}

.search-result-container {
    position: absolute;
    background-color: white;
    width: 100%;
    left: 0;
    margin-top: 1rem;
    border-radius: 20px;
    max-height: 325px;
    z-index: 10;
    padding: 1rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.search-result {
    width: 100%;
    overflow: auto;
    box-sizing: border-box;
}

.search-no-result {
    color: black;
    text-align: center;
}

.result-pic {
    min-height: 100%;
    display: flex;
    align-items: center;
}

.result-pic img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 20px;
}

.itoour-btn-all-result {
    background: #753183;
    color: white;
    font-weight: bold;
    text-align: center;
    padding: 0.3rem 0.5rem;
    width: calc(100% - 2rem);
    border-radius: 20px;
}

.itoour-btn-all-result:hover {
    background: #863896;
    color: white;
    text-decoration: none;
}

.result-container {
    display: flex;
    gap: 1rem;
    padding: 0.5rem 1rem;
}

.result-container:hover {
    text-decoration: none;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 20px;
}

.result-info {
    display: flex;
    flex-direction: column;
}

.result-info-region,
.result-info-duracion {
    color: #753183;
    font-size: 14px;
}

.result-info-region i,
.result-info-duracion i {
    margin-right: 0.5rem;
}

.result-info-titulo {
    color: black;
    font-size: 16px;
    font-weight: bold;
    line-height: 1.3;
    text-align: justify;
}

@media (max-width: 1440px) {
    .header .container p {
        font-size: 25px;
    }
}

@media (max-width: 1366px) {
    .header .container p {
        font-size: 25px;
    }
}

@media (max-width: 1280px) {
    .header .container p {
        font-size: 20px;
    }
}

@media (max-width: 1199px) {
    .texto-header-container {
        max-width: 500px;
    }
}

@media (max-width: 1080px) {
    .header .container p {
        font-size: 19px;
    }
}

@media (max-width: 991px) {
    .header .container p {
        max-width: 450px;
    }
}

@media (max-width: 900px) {
    .header .container p {
        font-size: 15px;
    }

    .header .container p {
        max-width: 400px;
    }

    .itoour-search-bar {
        max-width: 400px;
        font-size: 15px;
    }
}

@media (max-width: 767px) {
    .header .container p {
        font-size: 13px;
    }

    .header form input {
        margin-top: 10px;
    }

    .header .container p {
        max-width: 300px;
    }

    .itoour-search-bar {
        max-width: 400px;
        font-size: 13px;
    }
}

@media (max-width: 667px) {
    .header .container p {
        font-size: 13px;
    }

    .header {
        max-height: 90vh;
    }
}

@media (max-width: 575px) {
    .header .container {
        max-width: calc(100% - 2rem);
        margin: 0 1rem;
    }

    .itoour-search-bar {
        max-width: 100%;
    }
}

@media (max-width: 450px) {
    .texto-header {
        display: none;
    }

    .header form input {
        font-size: 12px;
    }
}
