@font-face {
    font-family: ArialMTBold;
    src: url(./assets/fonts/arlrdbd.ttf);
}

@font-face {
    font-family: ArialRounded;
    src: url(./fonts/unicode.arialr.ttf);
}

@font-face {
    font-family: KeepCalm;
    src: url(./fonts/KeepCalm-Medium.ttf);
}

.leaflet {
    height: 250px;
    width: 100%;
}

.btn-itoour {
    background-color: #753183 !important;
    color: white !important;
}

.btn-itoour:disabled,
.btn-itoour:disabled:hover,
.btn-itoour[disabled='disabled'] {
    background-color: #8b6194 !important;
    color: white !important;
}

.bgColorEditInput {
    background: #faf8ef;
}

.bgColorEditInput:focus {
    background: #faf8ef;
}

.btn-itoour:hover {
    background-color: #61266d !important;
    color: white !important;
}

.text-itoour {
    color: #753183 !important;
    font-family: 'Nunito', Arial, Helvetica, sans-serif !important;
}

.text-nunito {
    font-family: 'Nunito', Arial, Helvetica, sans-serif !important;
}

.text-helvetica {
    font-family: Helvetica, sans-serif !important;
}

.ul-itoour ul {
    list-style: none;
}

.ul-itoour ul li::before {
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: red; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em;
}

.paginate {
    background-color: #61266d !important;
    border-color: rgb(158, 79, 173) !important;
}

.logo {
    font-family: ArialMTBold !important;
    text-decoration: none;
    color: white;
    font-size: 1.5rem;
}

.logo span {
    font-family: ArialMTBold;
}

.logo:hover {
    color: #f5f5f5;
    text-decoration: none;
}

.map {
    height: 300px !important;
    content: '\2022'; /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: red; /* Change the color */
    font-weight: bold; /* If you want it to be bold */
    display: inline-block; /* Needed to add space between the bullet and the text */
    width: 1em; /* Also needed for space (tweak if needed) */
    margin-left: -1em;
}

.paginate {
    background-color: #61266d !important;
    border-color: rgb(158, 79, 173) !important;
}

.logo {
    font-family: ArialMTBold !important;
}

.map {
    height: 300px !important;
}

.wrapStatusPayment {
    text-align: center;
}
.wrapStatusPayment img {
    width: 120px;
    margin: 20px;
}
.wrapStatusPayment--error img {
    width: 80px;
}

input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
}
.switch-permiso {
    text-indent: -9999px;
    width: 50px;
    height: 25px;
    background: #28a745;
    display: block;
    border-radius: 8px;
    position: relative;
}
.switch-permiso:hover::before {
    content: '';
    width: 100%;
    min-height: 25px;
    background: rgba(0, 0, 0, 0.25);
    position: absolute;
    border-radius: 8px;
    top: 0;
    left: 0;
}
.switch-permiso:after {
    content: '';
    position: absolute;
    top: 2.5px;
    left: 5px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 6px;
    transition: 0.3s;
}
input:checked + .switch-permiso {
    background: #b30b00;
}
input:checked + .switch-permiso:after {
    left: calc(100% - 5px);
    transform: translateX(-100%);
}
.switch-permiso:active:after {
    width: 40px;
}

.textArea__content {
    resize: none;
}

.my-swal {
    z-index: 1000000000000 !important;
}

.read__btn {
    color: #0984e3;
}
.read__btn:hover {
    cursor: default;
    color: rgba(9, 133, 227, 0.6);
}

.MuiButtonBase-root {
    outline: none !important;
}

.text__notificaciones {
    font-size: 0.8rem;
    cursor: default;
}

.modal__notificaciones:link {
    text-decoration: none;
}
.modal__notificaciones:link:hover {
    background: #ccc;
    border-radius: 7px;
}
.modal__notificaciones--container {
    padding: 5px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
/*.modal__notificaciones--container:hover {
  background: #ccc;
}*/
.modal__notificaciones--container--default {
    padding: 5px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.btn-save {
    font-size: larger;
    font-weight: bold;
}

.msg-verificacion-container {
    background: #cc832f;
    padding: 0.4rem 0.5rem;
    display: flex;
    flex-direction: column;
    color: rgba(255, 255, 255, 0.8);
    gap: 5px;
}

.msg-verificacion-container > .msg-verificacion {
    color: #6e2f7b;
    font-family: ArialMTBold, Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: bold;
}

.msg-verificacion-container > div {
    display: flex;
    align-items: center;
    gap: 10px;
}

.msg-verificacion {
    font-size: 12px;
    font-family: ArialMTBold, Arial, Helvetica, sans-serif;
    text-align: justify;
}

.verificar {
    padding: 0.1rem 0.4rem;
    border-radius: 7px;
    box-shadow: 0 0 5px rgba(110, 47, 123, 0.7);
    background: #cc832f;
    font-size: 13px;
    font-weight: bold;
    font-family: ArialMTBold, Arial, Helvetica, sans-serif;
    border: 1px solid #cc832f;
    color: #6e2f7b;
    animation-name: btn-on-off;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
}

.verificar:hover {
    cursor: pointer;
    border: 1px solid #6e2f7b;
    background: #6e2f7b;
    color: white;
}

.eye-password {
    background-color: #fff !important;
    border-color: #fff !important;
}

.eye-password:hover {
    cursor: pointer;
    color: black;
}

.siq_bR {
    height: auto !important;
    width: auto !important;
}

#zsiq_float img {
    width: 55px;
}

.lista-busqueda-tours {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    gap: 5px;
    width: 100%;
    height: 300px;
    max-width: 650px;
    z-index: 2;
}

.lista-busqueda-tours .busqueda-item {
    width: 90vw;
}

.lista-busqueda-tours .busqueda-item a {
    background-color: white;
}

.link-style-carrousel:hover {
    cursor: pointer;
}

@keyframes btn-on-off {
    from {
        border-color: #cc832f;
    }

    to {
        border-color: #6e2f7b;
    }
}

@media (max-width: 367px) {
    .msg-verificacion-container > div {
        justify-content: center;
    }

    .msg-verificacion {
        text-align: center;
    }
}
