.destinos .container {
    width: 100%;
    margin-top: 40px;
    /*display:grid;
    grid-template-columns: repeat(4,1fr);
    grid-gap:40px;
    padding:0 100px;*/
    display: flex;
    flex-direction: column;
}
.destinos .container .tarjeta {
    border-radius: 5px;
    box-shadow: 2px 2px 10px gray;
    background-color: white;
    width: 205px;
    margin: 10px;
    height: 370px;
}
.destinos .container .tarjeta h4 {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 20px 0 20px;
}
.destinos .container .tarjeta p {
    font-size: 12px;
    margin: 0 20px;
    margin-top: 5px;
}

/*.destinos .container .titulo{
    grid-column: 1 / 5;
}*/

.destinos .container .tarjeta .img {
    height: 270px;
    background-image: url('../../../assets/machupichu.png');
    background-size: cover;
    background-position: center;
}
/*.destinos .container .tarjeta .cuerpo{
    padding:20px;
}*/

/*@media (max-width:980px){
    .destinos .container{
        margin-top:40px;
        display:grid;
        grid-template-columns: repeat(1,1fr);
        grid-gap:0px;
    }

    .destinos .container .tarjeta{
        grid-column: 1/1;
        margin-bottom: 25px;
    }
}*/

.cards-regiones {
    display: flex;
    max-width: 100%;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
}

.cards-regiones > div {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    flex-wrap: wrap;
    width: 1051px;
}

@media (max-width: 1199px) {
    .cards-regiones > div {
        width: 785px;
    }
}

@media (max-width: 991px) {
    .cards-regiones > div {
        width: 519px;
    }
}

@media (max-width: 767px) {
    .cards-regiones > div {
        width: 253px;
    }
}

.btn-ver-mas-destinos {
    width: max-content;
    border: 2px solid #753183;
    background-color: white;
    padding: 0.5rem 1rem;
    border-radius: 15px;
    /* font-weight: bold; */
    font-size: 14px;
    /* border-radius: 50%;
    width: 2rem;
    height: 2rem; */
    outline: none;
}

.btn-ver-mas-destinos:hover {
    cursor: pointer;
    background-color: #753183;
    color: white;
}

.btn-ver-mas-destinos:focus {
    outline: #753183;
}

.card-region {
    width: 250px;
    height: 288px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 7px;
    overflow: hidden;
}

.image-region {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    max-width: 100%;
    min-height: 100%;
    z-index: -1;
    border-radius: 7px;
}

.detalles-region {
    background: rgba(117, 49, 131, 1);
    color: white;
    padding: 1rem;
    padding-top: 0.25rem;
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    overflow: hidden;
    height: 2.3rem;
    width: 160px;
    text-align: left;
    border-top-left-radius: 7px;
    border-bottom-right-radius: 15px;
    transition: all 0.7s ease-in-out;
}

.detalles-region .nombre-region {
    font-weight: bold;
    font-size: 18px;
    /* margin: 0 0.5rem; */
    transition: all 0.7s ease-in;
}

.detalles-region .descripcion-region {
    text-align: justify;
    font-size: 15px;
    width: calc(250px - 2rem);
    box-sizing: border-box;
    color: rgba(1, 1, 1, 0);
    line-height: 1.2;
    transition: all 0.7s ease-in; /* 0.7s;*/
}

.card-region .link-region {
    border: 1px solid #db6e15;
    background: #db6e15;
    text-decoration: none;
    color: white;
    font-weight: bold;
    font-size: 12px;
    padding: 0.3rem 0.6rem;
    border-radius: 8px;
    text-align: center;
    width: max-content;
    margin: auto;
    margin-bottom: 1rem;
}

.card-region .link-region:hover {
    background: #e37b25;
    border: 1px solid #e37b25;
}

.card-region:hover .detalles-region {
    width: 100%;
    height: 195px;
    background: rgba(117, 49, 131, 0.7);
    border-bottom-right-radius: 0px;
    border-top-right-radius: 7px;
    text-align: center;
}

.card-region:hover .detalles-region .descripcion-region {
    color: white;
}

/*.card-region:not(:hover) .detalles-region .descripcion-region {
    transition: none;
}*/
