.tours-stats-container {
    width: 100%;
    max-width: max-content;
    margin: 3rem auto;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    /* box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3); */
}

.tours-stat {
    width: 140px;
    text-align: center;
    padding: 0.5rem;
    border-radius: 7px;
    box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
}

.tours-stat-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.tours-stat-value {
    font-size: x-large;
    margin: 0;
}

.tour-admin-desc div {
    width: 300px !important;
}

.tour-admin-status {
    width: 120px;
}

.status-tour-span {
    color: white;
}

.field-admin-dashboard {
    text-align: center;
}
