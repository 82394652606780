.navitoour {
    background-color: #753183 !important;
    box-shadow: 5px 5px 20px gray !important;
}

.guia {
    /* background-color: #d6872b; */
    color: white;
    border-radius: 10px 10px 10px 10px;
    max-width: max-content;
    /* font-weight: bolder; */
    padding: 5 10px;
    padding-left: 10px !important;
    padding-right: 10px !important;
    font-size: large;
    line-height: 2;
}

.guia.no-line {
    line-height: normal;
}

.guia:hover {
    background-color: #d6872b;
}

.navbar-menu .nav-item .nav-link:not(.guia) {
    color: rgba(255, 255, 255, 0.7) !important;
}

.navbar-menu .nav-item:hover .nav-link:not(.guia) {
    color: rgba(255, 255, 255, 1) !important;
}

.transparent {
    position: absolute !important;
}
.transparent * {
    position: relative;
    color: black;
}

.link-button {
    cursor: pointer;
}

.notificaciones {
    border: 1px solid red !important;
}

.acc-mobile {
    display: none;
    color: rgba(255, 255, 255, 0.5);
}

.acc-mobile:hover {
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
}

@media (max-width: 991px) {
    .navitoour {
        padding-top: 0.5rem !important;
        padding-bottom: 0.5rem !important;
    }
    .acc-menu {
        display: none;
    }

    .acc-mobile {
        display: list-item;
    }

    #basic-navbar-nav {
        margin-bottom: 1rem;
        margin-top: 1rem;
    }

    #basic-navbar-nav .navbar-nav {
        display: block;
    }
}
