.vertour {
    display: grid;
    grid-template-columns: 0.1fr 0.9fr;
    gap: 40px;
}

.info .carousel {
    margin-bottom: 30px;
}

.info h4 {
    font-weight: bold;
}

.info .titulo {
    display: grid;
    grid-template-columns: 0.8fr 0.2fr;
    margin-bottom: 20px;
}
.info .titulo div:nth-of-type(2) {
    color: #753183;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
}
.cant {
    font-size: 30px;
}
.busqueda {
    padding: 30px;
}

.imagen-slider-container {
    aspect-ratio: 16/9;
}

.imagen-slider {
    object-fit: cover;
}

.box-tour-precio {
    display: flex;
    gap: 10px;
    margin-left: 10px;
}

.box-tour-precio div {
    display: flex;
    flex-direction: column;
    width: max-content;
    height: min-content;
}

.box-tour-precio div h3 {
    margin: 0;
}
