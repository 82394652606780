.card-tour a {
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
}

.card-tour .tipo-tour,
.card-tour .titulo-tour,
.card-tour .region-tour,
.card-tour .precio-tour {
    color: white;
}
