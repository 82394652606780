.lista {
    width: 650px;
    margin-top: 0px;
    padding-top: 0px;
    border-top: 0px;
}
.imagen {
    width: 100px;
    height: 100px;
    margin-top: 0px;
    padding-top: 0px;
    border-radius: 10px;
    margin-right: 15px;
}
