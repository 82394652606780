.comprar-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    width: calc(100% - 275px);
}

.comprar-datos-contacto-titulo {
    width: 100%;
    text-align: center;
}

.comprar-datos-contacto-titulo h5 {
    font-weight: bold;
}

.comprar-datos-contacto {
}

@media (max-width: 991px) {
    .comprar-container {
        width: calc(100% - 175px);
    }
}

@media (max-width: 767px) {
    .comprar-container {
        width: 100%;
    }
}
