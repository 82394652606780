.mobile {
    display: none;
}

.mobile .nav {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
}

.mobile .nav .nav-link {
    white-space: nowrap;
}

@media (max-width: 991px) {
    .desktop {
        display: none;
    }

    .mobile {
        display: block;
    }
}

@media (max-width: 505px) {
    .mobile .nav {
        justify-content: flex-start !important;
    }
}
