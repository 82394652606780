.container-filtro {
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow-y: scroll;
    width: max-content;
    padding: 10px;
}

.select-field-search {
    max-width: 235px;
}
