.modal-background {
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
}

.modal-itoour {
    position: fixed;
    width: 315px;
    min-height: 460px;
    top: calc((100% - 400px) / 2);
    left: calc((100% - 300px) / 2);
    z-index: 1001;
    background-color: white;
    border-radius: 7px;
    overflow: auto;
}

.show-modal {
    display: block;
}

.show-modal .modal-itoour {
    display: block;
}

.modal-itoour-header {
    width: 100%;
    height: 85px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
    background-color: #61266d;
    display: flex;
    flex-direction: column;
    color: white;
    font-weight: bold;
    font-size: 19px;
    padding: 0.5rem 1rem;
}

.field-login-modal label,
.field-login-modal input,
.login-logo-itoour,
.slogan-itoour,
.link-reset-pasword,
.login-btn,
.text-register {
    font-family: ArialMTBold, Arial, Helvetica, sans-serif;
}

.btn-close-modal-login {
    position: absolute;
    top: 0;
    right: 0.5rem;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 20px;
    font-weight: normal;
}

.btn-close-modal-login:hover {
    cursor: pointer;
    color: lightgray;
}

.login-logo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    height: 50%;
}

.login-logo-itoour {
    display: flex;
    align-items: center;
}

.login-logo-itoour img {
    width: 120px;
    margin-left: -0.5rem;
}

.slogan-itoour {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50%;
}

.modal-itoour-body {
    box-sizing: border-box;
    height: calc(100% - 85px);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    padding: 1rem 2rem;
    position: relative;
}

.modal-itoour-body::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.6;
    background-image: url(https://firebasestorage.googleapis.com/v0/b/itoour-5f3b6.appspot.com/o/static%2FResumen%2Fmarcadeagua.png?alt=media&token=c587bd46-1cc7-4843-af9b-e6cbbd4509d5);
    background-repeat: no-repeat;
    background-position: right;
    background-size: 120px;
    z-index: -1;
}

.form-login-modal {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.field-login-modal {
    display: flex;
    flex-direction: column;
}

.field-login-modal.center {
    align-items: center;
}

.input-login-modal {
    font-size: 12px;
    border: 1px solid #61266d;
    border-radius: 7px;
    font-family: Arial, Helvetica, sans-serif !important;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.input-login-modal input:focus {
    outline: none;
    box-sizing: border-box;
    border-bottom: 1px solid #753183;
}

.input-login-modal input {
    width: calc(100% - 50px);
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    box-sizing: border-box;
    background-color: transparent;
    margin: 0.3rem 0 0.3rem 0.5rem;
}

.btn-icon-login {
    width: 30px;
    height: 32px;
    padding: 0;
    background-color: transparent;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-cursor-pointer:hover {
    cursor: pointer !important;
}

.btn-icon-login.box-with-border {
    border-left: 1px solid #61266d;
    box-sizing: border-box;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
}

.btn-icon-login:focus {
    border: none;
    outline: none;
}

.btn-icon-login .btn-icon-eye-password {
    font-weight: bold;
    font-size: 16px;
    color: #61266d;
}

.btn-icon-login .btn-icon-eye-password:hover {
    color: #753183;
}

.btn-icon-login .btn-icon-email {
    font-weight: bold;
    font-size: 16px;
    color: #61266d;
}

.link-reset-pasword {
    font-size: 12px;
    color: black;
}

.link-reset-pasword:hover {
    color: black;
}

.login-btn-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.login-btn {
    border-radius: 7px;
    background-color: #61266d;
    color: white;
    font-size: 15px;
    width: max-content;
    padding: 0.2rem 1rem;
    border: none;
}

.login-btn:hover {
    background-color: #753183;
}

.login-btn:focus {
    outline-color: #753183;
}

.login-btn-google {
    background-color: #db6e15;
    width: 100%;
}

.login-btn-google:hover {
    background-color: #e37b25;
}

.login-btn-google:focus {
    outline-color: #e37b25;
}

.login-separador {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 150px;
    margin: auto;
}

.login-linea-separador {
    background-color: black;
    width: calc(50% - 23px);
}

.login-o-tipo {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.text-register {
    font-weight: bold;
    font-size: 12px;
}

.link-register-itoour {
    color: #61266d;
    text-decoration: underline;
}

.link-register-itoour:hover {
    color: #753183;
}
