.fab-wsp {
    padding: 10px;
    font-size: 25px;
    width: 45px;
    text-align: center;
    text-decoration: none;
    margin: 5px 2px;
    border-radius: 50%;
    position: fixed;
    right: 8px;
    bottom: 5px;
}

.fab-wsp:hover {
    background: #25d366;
    text-decoration: none;
    color: white;
}

.fa-wsp {
    background: #0ebe43;
    color: white;
    z-index: 1;
}
