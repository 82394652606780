/*.tablaReservas thead tr {
    background-color: #753183;
}*/

.compras-container {
    max-width: 100vw;
    overflow-x: scroll;
    margin-top: 2rem;
}

.tablaReservas {
    width: max-content;
}

.tablaReservas thead tr th {
    color: #fff;
    font-size: 1.2em;
    font-weight: bold;
}

.tablaReservas tr {
    text-align: center;
}

.tourNombreReserva {
    max-width: 150px;
}

.showReserva {
    font-size: 22px;
}

.showReserva:hover {
    color: #753183;
    cursor: pointer;
}

#contained-modal-title-vcenter {
    font-weight: bold;
}

.detalles-tour-modal p {
    margin: 0;
}

.modalReservas {
    height: 620px;
}

.accordion-body-modal {
    height: 600px;
    padding: 0;
}

.accordion-body-modal .row {
    margin: 0;
}

.accordion-body-modal .row:first-child {
    font-size: 1.3em;
    font-weight: bold;
    padding: 10px 0 0 15px;
}

.accordion-body-modal .row .col-lg-12 {
    padding: 0;
}
