.ltours h3 {
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 40px;
}

.ltour {
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-bottom: 20px;
    height: 220px;
    cursor: pointer;
}
.ltour:hover {
    box-shadow: 0 3px 15px 0 rgb(187, 187, 187);
}
.ltour .ltourimagen {
    width: 220px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.ltour .ltourimagen img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.ltoursinfo {
    padding: 20px;
}
.ltoursinfo .icon {
    color: #d6872b;
}

.ltoursinfo h5 {
    font-weight: bold;
}

.ltoursinfo .duracion {
    font-size: 12px;
}

.ltoursinfo .precio {
    color: #753183;
    font-weight: bold;
}

.ltoursinfo p {
    font-size: 12px;
}

.container-tours {
    display: flex;
    flex-direction: column;
    width: max-content;
}

.container-tours .container-list-tours {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-left: 40px;
}

.container-tours .container-list-tours .card-lugares {
    max-width: 300px;
}

.container-list-tours .card-lugares {
    margin: 0 1rem !important;
}

.container-list-tours .card-lugares .card {
    min-height: 29.5rem;
}

.container-list-tours .card-lugares .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.container-list-tours .card-lugares .card-body .card-info {
    justify-self: flex-end;
}

.card-img-top {
    object-fit: cover;
}

.no-tours {
    text-align: center;
    margin: 0 2rem;
}
