.contenedor {
    display: grid;
    height: calc(100% - 54px);
    grid-template-columns: 4fr 6fr;
    font-family: 'Nunito', sans-serif;
}
.contenedor .imagenLogin {
    background-image: url('../../assets/Login.png');
    background-position: center;
    background-size: cover;
    padding: 20px 0 0 30px;
    color: white;
    /* font-weight:bold; */
    font-size: 2rem !important;
}
.contenedor .imagenregistro {
    background-image: url('../../assets/registro.png');
    background-position: center;
    background-size: cover;
    padding: 20px 0 0 30px;
    color: white;
    /* font-weight:bold; */
    font-size: 2rem !important;
}
.formulario {
    display: grid;
    align-content: center;
    justify-content: center;
}

.formulario .card {
    box-shadow: 5px 5px 10px gray;
    min-width: 500px;
    border-radius: 20px;
}

.formulario .card-header {
    background-color: #753183;
    color: white;
    text-align: center;
    font-size: 28px;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
.btn-login {
    background-color: #753183;
    font-weight: bold;
}
.btn-login:hover {
    background-color: #622c6d;
}
#forgot {
    color: #d6872b;
}
#registro span:nth-child(1) {
    color: #2d333c;
}
#registro span:nth-child(2) {
    color: #d6872b;
}

.separador-login {
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
}

.linea-separador {
    border: 1px solid black;
    width: calc(50% - 23px);
}

.btn-login-google i {
    margin-right: 5px;
    font-size: 20px;
}

.auth-email-input {
    width: calc(100% - 44px);
}

@media screen and (max-width: 1000px) {
    .contenedor {
        grid-template-columns: 1fr;
        grid-template-rows: 150px 1fr;
    }

    .formulario {
        padding: 30px;
    }
    .formulario .card {
        min-width: 0px;
    }
}
