.logo_img {
    width: 12rem;
}

.headercontacto {
    background-image: url('../assets/Contacto.jpg');
    background-position: center;
    background-size: cover;
    padding: 100px 0;
    padding-bottom: 180px;
    color: white;
    width: 100vw 100%;
    height: calc(100vw / 1.78);
    max-height: 100vh;
}

.descubre {
    font-size: 60px;
    font-weight: bold;
}

.contacto__info a {
    text-decoration: none;
    color: black;
}
.contacto__info a:hover {
    text-decoration: none;
    color: #d6872b;
}

.contacto__formulario--item button {
    color: black;
    background-color: transparent;
    border-color: rebeccapurple;
    height: 60px;
    font-size: larger;
    border-radius: 30px;
}

.contacto__formulario--item button:hover {
    color: white;
    background-color: rebeccapurple;
    border-color: rebeccapurple;
}

.contacto__formulario input {
    height: 60px;
}
