footer {
    background-color: #222831;
    color: white;
}

.page-footer a {
    text-decoration: none;
    color: #bcbec0;
}

.page-footer a:hover {
    text-decoration: none;
    color: #d6872b;
}

.footer-link {
    color: white !important;
}

.footer-link:hover {
    color: rgba(255, 255, 255, 0.8) !important;
}

.footer-redes-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* gap: 5px; */
}

.footer-redes-list li {
    margin-bottom: 10px;
}

@media (min-width: 768px) {
    .redes-sociales-icons {
        position: absolute;
        left: 0;
        width: 300px;
    }
}
