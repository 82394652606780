.logo_img{   
    width: 12rem;
}

.headernosotros {
    background-image: url("../assets/NosotrosImg.png");
    background-position: center;
    background-size: cover;
    padding: 100px 0;
    padding-bottom: 180px;
    color: white;
}

.descubre {    
    
    font-size: 60px;
    font-weight: bold;
}


.info__img{
    display: flex;
    justify-content: center;
    align-items: center;
}

.info__text{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.info__text p{
    display: flex;
    justify-content: space-around;
}

.info__text .btn-primary{
    background-color: white;
    border-color: rebeccapurple;
    color: black;
    outline: none;
    /* height: 60px;
    width: 150px; */
    font-weight: bold;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .info__text .btn-primary a {
    padding: 5px 10px 5px 10px;
} */

.info__text .btn-primary:focus{
    background-color: rebeccapurple;
    border-color: rebeccapurple;
    outline: none;
    box-shadow: none
}

.info__text .btn-primary:hover{
    background-color: rebeccapurple;
    border-color: rebeccapurple;
}

.info__text .card{
    font-size: 20px;
    text-align: justify;
    border: none;    
    box-shadow: 5px 5px 20px rgb(155, 155, 155);
    border-radius: 20px;
    margin-bottom: 40px;
}

/* Q and A section */


.pregunta{
    background-color: #F9FAFF;
}



/* test */

/* .accordion .card-header:after {
    font-family: 'FontAwesome';  
    content: "\f056";
    float: right; 
    font-size: x-large;
    color: #D6872B;

}
.accordion .card-header.collapsed:after {
    content: "\f055"; 
} */

.collapse__container{
    margin: 10px;
    border-radius: 40px;
    
}

.card.mb-0 {
    background-color: transparent !important;
    border: none !important;
}



.collapse__container .collapse{
    box-shadow: 5px 5px 20px rgb(196, 196, 196);
    border-radius: 0px 0px 20px 20px;
}