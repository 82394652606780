.registro-cliente-container {
    font-family: KeepCalm, Arial, Helvetica, sans-serif;
    width: 100%;
    height: max-content;
    min-height: calc(100% - 56px);
    display: flex;
    position: relative;
}

.registro-cliente-container .foto-guia {
    width: 40%;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
}

.registro-cliente-container .foto-guia img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: -5rem 0% !important;
}

.registro-cliente-container .form-container {
    width: 60%;
    height: max-content;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: white;
}

.form-container .stepPageContainer {
    padding: 2rem;
}

.form-container .stepFormContainer {
    width: 100%;
    height: max-content;
}

.stepFormContainer form {
    display: flex;
    flex-direction: column;
    margin: 0 5rem;
    box-sizing: border-box;
    gap: 1rem;
}

.row-form {
    display: flex;
    gap: 2rem;
}

.row-form.center {
    justify-content: center;
}

.col-form {
    display: flex;
    flex-direction: column;
    width: calc(50% - 1rem);
}

.col-form.center {
    align-items: center;
}

.col-form.full-width {
    width: 100%;
}

.col-form.w-40 {
    width: calc(40% - 1rem);
}

.col-form.w-60 {
    width: calc(60% - 1rem);
}

.col-form.w-40.full-width {
    width: 40%;
}

.col-form.w-60.full-width {
    width: 60%;
}

.col-form.w-33 {
    width: calc(33% - 1rem);
}

.col-form.w-33.full-width {
    width: 33%;
}

.col-form.w-25 {
    width: calc(25% - 1rem);
}

.col-form.w-25.full-width {
    width: 25%;
}

.col-form p {
    margin: 0;
}

.col-form .label-field {
    font-family: KeepCalm, Arial, Helvetica, sans-serif;
    font-weight: normal;
    margin-bottom: 0.5rem;
}

.col-form .input-text-field {
    border: 1px solid #753183;
    border-radius: 10px;
    padding: 0.3rem 0.5rem;
    height: 36px;
    box-sizing: border-box;
    outline: none;
}

.col-form .input-text-field:disabled {
    background: rgba(117, 49, 131, 0.1);
}

.col-form.inline {
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
}

.col-form.inline.center {
    justify-content: center;
}

.col-form.inline * {
    margin: 0;
}

.selector-container {
    border: 1px solid #753183;
    border-radius: 10px;
    display: flex;
    height: 36px;
    box-sizing: border-box;
}

.select-documents {
    border: 1px solid #753183;
    outline: none;
    width: 60px;
    background: #753183;
    height: 100%;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    color: white;
    font-family: KeepCalm, Arial, Helvetica, sans-serif;
    text-align: center;
}

.select-documents option {
    background-color: white;
    color: black;
    font-family: KeepCalm, Arial, Helvetica, sans-serif;
}

.selector-container input[type='text'] {
    border: none;
    border-radius: 0;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 100%;
    width: calc(100% - 60px);
}

.select-tipo {
    width: 100%;
    height: 36px;
    border: 1px solid #753183;
    border-radius: 10px;
    outline: none;
    text-align: center;
    font-family: KeepCalm, Arial, Helvetica, sans-serif;
    color: white;
    background: #753183;
}

.select-tipo option {
    background: white;
    color: black;
    font-family: KeepCalm, Arial, Helvetica, sans-serif;
}

.select-telefono-container {
    border: 1px solid #753183;
    border-radius: 10px;
    display: flex;
}

.select-telefono-container input {
    border: none !important;
    border-radius: 10px !important;
    box-shadow: none !important;
}

.input-password {
    border: 1px solid #753183;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 36px;
    box-sizing: border-box;
}

.input-password input {
    border: none !important;
    outline: none;
    width: calc(100% - 30px);
    max-height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 0.3rem 0.5rem;
}

.input-password span {
    width: 30px;
    height: min-content;
    text-align: center;
    color: #753183;
}

.input-password span:hover {
    color: #863896;
    cursor: pointer;
}

.btn-sgte-resgistro {
    border: none;
    outline: none;
    padding: 0.3rem 3rem;
    border-radius: 20px;
    background-color: #753183;
    font-family: KeepCalm, Arial, Helvetica, sans-serif;
    color: white;
    margin: 1rem 0;
}

.btn-sgte-resgistro:hover {
    background-color: #863896;
}

.btn-sgte-resgistro:focus {
    outline: none;
}

.btn-sgte-resgistro:disabled {
    background-color: rgba(117, 49, 131, 0.7);
}

.foto-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.foto-profile {
    display: flex;
    width: 100px;
    height: 100px;
    position: relative;
    border-radius: 50%;
    border: 1px solid black;
}

.foto-profile .foto-btn {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.8);
    font-weight: bold;
    top: 0;
    left: 0;
}

.foto-profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.foto-profile:hover {
    cursor: pointer;
}

.foto-profile:hover .foto-btn {
    display: flex;
}

.form-container span {
    font-family: KeepCalm, Arial, Helvetica, sans-serif;
}

.form-container span:hover {
    text-decoration: underline;
    cursor: pointer;
}

.disabled-field {
    font-family: Arial, Helvetica, sans-serif;
    color: gray;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto !important;
    min-height: 36px !important;
}

.input-field-partial {
    box-sizing: border-box;
    width: calc(100% - 60px);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.disabled-select {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-text-field-telefono {
    font-family: Arial, Helvetica, sans-serif;
    color: gray !important;
    text-align: center;
}

.input-text-field-telefono:hover {
    cursor: default;
}

.react-tel-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
    border: none;
    box-shadow: none;
    outline: none;
}

.check-terminos {
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
}

.check-terminos:checked {
    accent-color: #753183;
}

.chk-terminos {
    color: black;
    text-decoration: underline;
}

.chk-terminos:hover {
    color: gray;
}

.select-telefono-container {
    padding: 0 !important;
}

.select-telefono-container input {
    width: 100% !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    max-height: 34px !important;
}

@media (min-width: 1001px) {
    .registro-cliente-container .form-container {
        padding: 0 2rem;
    }

    .registro-cliente-container .foto-guia img {
        width: 100%;
        object-position: -12rem 0% !important;
    }

    .stepFormContainer form {
        margin: 0;
        box-sizing: border-box;
        gap: 2rem;
    }

    .check-terminos-politicas {
        width: max-content !important;
    }
}

@media (max-width: 1000px) {
    .registro-cliente-container {
        flex-direction: column;
        width: 100%;
        max-width: 100vw;
    }

    .registro-cliente-container .foto-guia {
        width: 100%;
        max-width: 100vw;
        height: auto;
        position: relative;
    }

    .registro-cliente-container .foto-guia img {
        width: 100%;
        object-position: center !important;
    }

    .registro-cliente-container .form-container {
        padding: 0 1rem;
        width: 90%;
        margin: auto;
        position: absolute;
        left: 5%;
        top: 450px;
    }

    .stepFormContainer form {
        margin: 0;
        box-sizing: border-box;
        gap: 0.5rem;
    }

    .col-form .input-text-field {
        padding: 0.2rem 0.5rem;
    }

    .select-telefono-container {
        padding: 0 !important;
    }

    .select-telefono-container input {
        width: 100% !important;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .row-form {
        gap: 1rem;
    }

    .col-form {
        width: calc(50% - 0.5rem);
    }

    .col-form.w-40 {
        width: calc(40% - 0.5rem);
    }

    .col-form.w-60 {
        width: calc(60% - 0.5rem);
    }

    .col-form.w-33 {
        width: calc(33% - 0.5rem);
    }

    .col-form.w-25 {
        width: calc(25% - 0.5rem);
    }

    .check-terminos-politicas {
        width: max-content !important;
    }
}

@media (max-width: 720px) {
    .registro-cliente-container .form-container {
        padding: 0 1rem;
        top: 350px !important;
    }

    .stepFormContainer form {
        font-size: 15px !important;
    }

    .col-form .label-field {
        font-size: 15px !important;
        margin-bottom: 0.5rem;
    }

    .select-telefono-container input {
        max-height: 34px !important;
        font-size: 15px !important;
    }

    .col-form .input-text-field {
        height: 36px;
    }

    .selector-container {
        height: 36px;
    }

    .select-tipo {
        height: 36px;
    }

    .input-password {
        height: 36px;
    }

    .disabled-field {
        min-height: 36px !important;
    }

    .check-terminos-politicas {
        width: max-content !important;
    }
}

@media (max-width: 600px) {
    .registro-cliente-container .form-container {
        padding: 0 1rem;
        top: 320px !important;
    }
}

@media (min-width: 481px) AND (max-width: 560px) {
    .registro-cliente-container .form-container {
        padding: 0 1rem;
        top: 270px !important;
    }

    .stepFormContainer form {
        font-size: 12px !important;
    }

    .col-form .label-field {
        font-size: 12px !important;
        margin-bottom: 0.5rem;
    }

    .select-telefono-container input {
        max-height: 28px !important;
        font-size: 12px !important;
    }

    .col-form .input-text-field {
        height: 30px;
    }

    .selector-container {
        height: 30px;
    }

    .select-tipo {
        height: 30px;
    }

    .input-password {
        height: 30px;
    }

    .disabled-field {
        min-height: 30px !important;
    }
}

@media (min-width: 441px) AND (max-width: 480px) {
    .registro-cliente-container .form-container {
        padding: 0 1rem;
        top: 250px !important;
    }

    .select-telefono-container input {
        max-height: 23px !important;
        font-size: 10px !important;
    }

    .stepFormContainer form {
        font-size: 10px !important;
    }

    .col-form .label-field {
        font-size: 10px !important;
        margin-bottom: 0.5rem;
    }

    .col-form .input-text-field {
        height: 25px;
    }

    .selector-container {
        height: 25px;
    }

    .select-tipo {
        height: 25px;
    }

    .input-password {
        height: 25px;
    }

    .disabled-field {
        min-height: 25px !important;
    }
}

@media (min-width: 400px) AND (max-width: 440px) {
    .registro-cliente-container .form-container {
        padding: 0 1rem;
        top: 200px !important;
    }

    .select-telefono-container input {
        max-height: 23px !important;
    }
}

@media (max-width: 440px) {
    .registro-cliente-container .form-container {
        top: 180px !important;
    }

    .stepFormContainer form {
        font-size: 10px !important;
    }

    .col-form .label-field {
        font-size: 10px !important;
        margin-bottom: 0.3rem;
    }

    .col-form .input-text-field {
        height: 25px !important;
    }

    .selector-container {
        height: 25px !important;
    }

    .select-tipo {
        height: 25px !important;
    }

    .select-telefono-container input {
        max-height: 23px !important;
        font-size: 10px !important;
    }

    .input-password {
        height: 25px !important;
    }

    .disabled-field {
        min-height: 25px !important;
    }

    .selector-container {
        height: 25px !important;
    }

    .selector-container .input-text-field {
        max-height: 100% !important;
    }
}
