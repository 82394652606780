.tour-list-dashboard-container {
    overflow: auto;
    margin-bottom: 20px;
}

.tour-list-dashboard-container th {
    text-align: center;
}

.options-list-container {
    flex-wrap: wrap;
    width: 100%;
}

.options-list-container > div {
    margin-bottom: 10px;
}

.status-tour-span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    white-space: normal;
    border-radius: 5px;
    padding: 5px;
}

.tour-list-nombre {
    width: 300px;
}

.tour-list-ciudad {
    text-align: center;
}
