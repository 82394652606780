.foto-perfil {
    width: 200px;
    height: 200px;
}

#foto:hover ~ #foto::after {
    content: "Hola que tal?";
    display: block;
    width: 200px;
    height: 200px;
    background: #000;
}

.btn-subir-foto {
    border: none;
}
