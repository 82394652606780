.profile-pic {
    display: flex;
    justify-content: center;
}

.profile-pic img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    background-color: white;
}

.navitoour .dropdown-toggle::after {
    display: none;
}

.navitoour {
    padding-top: 0;
    padding-bottom: 0;
}

.dropdown-toggle.show ~ .profile-pic img {
    border: 2px solid #ccc;
}
