.map-container {
    width: 100%;
    margin-top: 2rem;
}

.map-container .search-map-input {
    padding: 0;
}

.map-search {
    padding: 0;
}

.map-search .search-map-buttons {
    align-items: center;
}
.map-container .search-map-buttons button {
    max-height: 35px;
}

.map-info {
    font-size: 1.5rem;
    font-weight: bold;
}

.map-duration {
    color: #d6872b;
}

.map-distance {
    color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 991px) {
    .map-container .search-map-input {
        margin-bottom: 1rem;
    }
}
