.reservaciones-filter {
    display: flex;
    gap: 20px;
    margin-bottom: 10px;
    justify-content: center;
    max-width: 100vw;
    flex-wrap: wrap;
    width: 100%;
}

.reservaciones-filter .MuiFormControl-root input {
    width: 100px;
}

.reservaciones-filter .reservaciones-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
    justify-content: center;
    padding: 0;
}

.reservaciones-filter .reservaciones-buttons button {
    padding-bottom: 0;
}

.tabla-reservas-container {
    max-width: 100vw;
    width: max-content;
    overflow: auto;
    height: 400px;
    border: 1px solid #e9ecef;
}

.tabla-reservas-container .tablaReservas thead tr {
    width: 'max-content';
}
